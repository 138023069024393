
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { Options, Vue, prop } from "vue-class-component";

class Props {
  modelValue = prop<boolean>({
    default: false
  });
  configName = prop<string>({
    default: ""
  });
  id = prop<number>({
    default: 0
  });
  status = prop<boolean>({
    default: false
  });
  type = prop<string>({
    default: ""
  });
}

@Options({
  emits: ["finish", "update:modelValue"],
  components: {}
})
export default class DeleteConfigPopup extends Vue.with(Props) {
  mounted() {
    this.confirmationModal = true;
  }
  // partner data
  get routeName() {
    return this.configName;
  }
  get idConfig() {
    return this.id;
  }
  get typeModal() {
    return this.type;
  }

  // Confirmation Modal
  confirmationModal = false;
  onCloseConfirmationModal() {
    this.confirmationModal = false;
    this.$emit("update:modelValue", false);
  }
  async onSubmitConfirmationModal() {
    MainAppController.showLoading();
    try {
      await CargoConfigurationController.onDelete(this.idConfig);
      this.confirmationModal = false;
    } catch (error) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(error, "Gagal Menghapus Konfigurasi kargo", () => {
          MainAppController.closeErrorMessage();
          this.onSubmitConfirmationModal();
        })
      );
    } finally {
      this.finishModal = true;
      MainAppController.closeLoading();
    }
  }
  get titleConfirmationModal() {
    return `Hapus Konfirmasi Kargo Rute “${this.routeName}” ?`;
  }
  get messageConfirmationModal() {
    return `Anda akan menghilangkan konfigurasi kargo <b>${this.routeName} beserta detailnya</b>`;
  }
  get buttonConfirmationModal() {
    return "Hapus";
  }

  // Finish Modal
  finishModal = false;
  onCloseFinishModal() {
    this.$emit("update:modelValue", false);
    if (this.typeModal === "list") {
      this.$emit("finish");
    } else {
      this.goToList();
    }
  }
  get titleFinishModal() {
    return "Konfirmasi Kargo Berhasil Dihapus";
  }
  get messageFinishModal() {
    return `Konfirmasi Kargo dengan rute <b>${this.routeName}</b> telah dihapus`;
  }
  goToList() {
    this.$router.push(`/network/cargo-configuration`);
  }
}
