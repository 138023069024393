
import { CARGO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";
import {
  checkRolePermission,
  parsingErrorResponse
} from "@/app/infrastructures/misc/Utils";
import { Vue, Options } from "vue-class-component";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import { CargoConfigListRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import { CityData } from "@/domain/entities/Location";
import AdvanceFilterCargoConfiguration from "@/app/ui/views/route/cargo-configuration/components/advance-filter-cargo-configuration.vue";
import DeleteConfigPopup from "@/app/ui/views/route/cargo-configuration/components/delete-config-popup.vue";

@Options({
  components: {
    AdvanceFilterCargoConfiguration,
    DeleteConfigPopup
  }
})
export default class CargoConfigurationList extends Vue {
  mounted() {
    CargoConfigurationController.resetFilter();
    this.fetchListCargoConfig();
  }
  get dataCargoConfig() {
    return this.cargoConfigList.data;
  }
  get firstRequest() {
    return CargoConfigurationController.firstRequest;
  }
  get isLoading() {
    return CargoConfigurationController.isLoading;
  }
  get errorCause() {
    return CargoConfigurationController.errorCause;
  }
  get pagination() {
    return this.cargoConfigList.pagination;
  }

  get cargoConfigList() {
    return CargoConfigurationController.cargoConfigList;
  }

  async fetchListCargoConfig() {
    CargoConfigurationController.setIsLoading(true);
    CargoConfigurationController.setErrorCause("");
    const origin = this.filterData.origin as CityData;
    const destination = this.filterData.destination as CityData;
    try {
      const result = await CargoConfigurationController.GetListCargoConfig(
        new CargoConfigListRequest({
          page: this.cargoConfigList.pagination.page,
          limit: this.cargoConfigList.pagination.limit,
          originCode: origin.code,
          destinationCode: destination.code,
          status: this.filterData.status,
          transportType: this.transportType.value,
          clientParentId: this.filterData.clientId
        })
      );
      CargoConfigurationController.setCargoConfigList(result);
    } catch (err) {
      CargoConfigurationController.setErrorCause(
        parsingErrorResponse(err).type
      );
    } finally {
      CargoConfigurationController.setIsLoading(false);
    }
  }

  columns = [
    {
      name: "ID",
      key: "Id",
      styleHead: "w-2/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Rute",
      key: "routeName",
      styleHead: "w-5/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class='text-left text-black-lp-300 flex justify-center'>${item.configName}</span>`;
      }
    },
    {
      name: "Kode Rute",
      key: "routeType",
      styleHead: "w-3/20 text-center",
      render: (item: any) => {
        return `
        <div class="text-black-lp-200 flex text-center">
            <div class="rounded px-2 py-0 bg-gray-lp-400">
              <span class="capitalize">${item.configCode}</span>
            </div>
          </div>`;
      }
    },
    {
      name: "Transportasi",
      key: "transport",
      styleHead: "w-3/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        const tranistUI = `<img src="${require("@/app/ui/assets/svg/plane-icon.svg")}" class="ml-2" /> <span class="ml-1">${
          item.totalTransit
        }</span>`;

        return `<div class="text-black-lp-200 flex text-center">
            <div class="rounded px-2 py-0 bg-gray-lp-400">
              <span class='capitalize'>${this.convertTransportName(
                item.configTransport
              )}</span>
            </div>
            ${
              item.configTransport === "Pesawat" && item.totalTransit
                ? tranistUI
                : ""
            }
        </div>`;
      }
    },
    {
      name: "Nama Klien",
      key: "clientName",
      styleHead: "w-5/20 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<span class='text-black-lp-300 flex justify-center'>${item.clientName}</span>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-2/20 text-left text-black-lp-300 whitespace-no-wrap",
      render: (item: any) => {
        return `<div class="flex">
        <div
          class="rounded-full px-2 py-0 text-center font-semibold ${
            item.configStatus.toLowerCase() === "active"
              ? "bg-green-lp-300 text-green-lp-200"
              : "bg-red-300 text-red-lp-200"
          }"
        ><span class="capitalize">${item.configStatus.toLowerCase() === "active" ? "Aktif" : "Tidak Aktif"}</span></div></div>`;
      }
    },
    {
      name: "Atur",
      key: "actionable_column",
      styleHead: "w-2/20 text-left",
      icon: "trash-concentrated",
      iconColor: "red-lp-100",
      disableButtonValue: false,
      hideIconTooltip: true,
      onClickValue: (item: any) => {
        this.configSelected = {
          id: item.id,
          configName: item.configCode,
          status: item.configStatus,
          type: "list"
        };
        this.deleteConfigModal = true;
      },
      showButton: (item: any) => item.configStatus.toLowerCase() !== "active"
    }
  ];

  // Misc Variable
  get filterData() {
    return CargoConfigurationController.filterData;
  }

  goToCreate() {
    this.$router.push("/network/cargo-configuration/add");
  }
  onClickRow(item: any) {
    this.$router.push(`/network/cargo-configuration/${item.id}`);
  }

  convertTransportName(val: string) {
    let name = "";
    switch (val.toLowerCase()) {
      case "plane":
        name = "Pesawat";
        break;
      case "truck":
        name = "Truk";
        break;
      case "train":
        name = "Kereta";
        break;
      default:
        name = val;
        break;
    }
    return name;
  }

  get isCreateAble() {
    return checkRolePermission(CARGO_CONFIGURATION.CREATE);
  }
  get isDetailAble() {
    return checkRolePermission(CARGO_CONFIGURATION.DETAIL);
  }

  // transport type
  get transportType() {
    return CargoConfigurationController.transportType;
  }

  onChangePagination(newPage: number) {
    this.cargoConfigList.pagination.page = newPage;
    this.fetchListCargoConfig();
  }

  // delete config
  deleteConfigModal = false;
  configSelected: any = "";
}
