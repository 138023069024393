import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock(_component_TableV2, {
    borderTop: false,
    borderBottom: false,
    isShowPagination: false,
    hidePagination: "",
    isNoPaddingBottom: true,
    isSpaceTop: false,
    columns: _ctx.columns,
    data: _ctx.dataSchedule,
    isRightFreeze: true,
    totalColumnFreeze: 0,
    headerTextColor: "text-black-lp-200"
  }, null, 8, ["columns", "data"]))
}