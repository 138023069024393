import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Modal = _resolveComponent("Modal")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        (_ctx.confirmationModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 0,
              onSubmit: _ctx.onSubmitConfirmationModal,
              onClose: _ctx.onCloseConfirmationModal,
              title: _ctx.titleConfirmationModal,
              message: _ctx.messageConfirmationModal,
              image: "badge-confirmation-general",
              textSuccess: _ctx.buttonConfirmationModal,
              textCancel: "Batal",
              class: "px-8"
            }, null, 8, ["onSubmit", "onClose", "title", "message", "textSuccess"]))
          : _createCommentVNode("", true),
        (_ctx.finishModal)
          ? (_openBlock(), _createBlock(_component_Modal, {
              key: 1,
              onSubmit: _ctx.onCloseFinishModal,
              title: _ctx.titleFinishModal,
              message: _ctx.messageFinishModal,
              image: "image-modal-success",
              textSuccess: "OK",
              class: "px-8"
            }, null, 8, ["onSubmit", "title", "message"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}