
import { Options, Vue, prop } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { OptionsClass } from "@/domain/entities/MainApp";
import { CityData } from "@/domain/entities/Location";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import { CargoConfigListRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import { ClientController } from "@/app/ui/controllers/ClientController";
import { ClientParentData } from "@/domain/entities/Client";

class Prop {
  isDisabled = prop<boolean>({
    type: Boolean,
    default: false
  });
}

@Options({
  emits: ["opened"],
  components: {},
  directives: {
    "click-outside": {
      beforeMount: function(el, binding) {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: function(el) {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class AdvanceFilterCargoConfig extends Vue.with(Prop) {
  isChevron = false;
  openFilter = false;
  get filterData() {
    return CargoConfigurationController.filterData;
  }
  get cargoConfigList() {
    return CargoConfigurationController.cargoConfigList;
  }
  mounted() {
    ClientController._onGetListParent();
  }
  async fetchListCargoConfig() {
    CargoConfigurationController.setIsLoading(true);
    CargoConfigurationController.setErrorCause("");
    const origin = this.filterData.origin as CityData;
    const destination = this.filterData.destination as CityData;
    try {
      const result = await CargoConfigurationController.GetListCargoConfig(
        new CargoConfigListRequest({
          page: this.cargoConfigList.pagination.page,
          limit: this.cargoConfigList.pagination.limit,
          originCode: origin.code,
          destinationCode: destination.code,
          status: this.filterData.status,
          transportType: this.transportType.value,
          clientParentId: this.filterData.clientId
        })
      );
      CargoConfigurationController.setCargoConfigList(result);
    } catch (err) {
      CargoConfigurationController.setErrorCause(
        parsingErrorResponse(err).type
      );
    } finally {
      CargoConfigurationController.setIsLoading(false);
    }
  }

  get roleAccount() {
    return (
      AccountController.accountData.account_type_detail.type ||
      AccountController.accountData.account_type
    );
  }
  get isClientParent() {
    return AccountController.accountData.account_type_detail.parentId === 0;
  }

  resetFilter() {
    this.transportType = new OptionsClass();
    this.filterData.status = "";
    this.clientValue = "";
    this.filterData.clientId = 0;
    this.filterData.clientName = "";
    this.cargoConfigList.pagination.page = 1;
    CargoConfigurationController.setTransportType(new OptionsClass());
    this.fetchListCargoConfig();
  }

  get sumFilter() {
    return (
      (this.transportType.value !== "" ? 1 : 0) +
      (this.filterData.status !== "" ? 1 : 0) +
      (this.clientValue !== "" ? 1 : 0)
    );
  }

  // transport type
  transportType = new OptionsClass();
  transportTypeData = [
    new OptionsClass({
      name: "Kapal",
      value: "ship"
    }),
    new OptionsClass({
      name: "Kereta",
      value: "train"
    }),
    new OptionsClass({
      name: "Pesawat",
      value: "plane"
    }),
    new OptionsClass({
      name: "Truk",
      value: "truck"
    })
  ];

  onChangePagination(newPage: number, val: any) {
    this.cargoConfigList.pagination.page = newPage;
    CargoConfigurationController.setTransportType(val);
    this.fetchListCargoConfig();
  }

  // filter status
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.filterData.status = value;
    this.cargoConfigList.pagination.page = 1;
    this.onCloseStatusSelect();
    this.fetchListCargoConfig();
  }

  clientValue: any = "";
  get isLoadingClientPartner() {
    return ClientController.isLoading;
  }

  get mappedDataClient() {
    return ClientController.clientParentData.map(
      (client: ClientParentData) => ({
        id: client.id,
        name: client.name,
        code: client.code,
        display: `${client.code}-${client.name?.toUpperCase()}`,
        isActive: false
      })
    );
  }

  onChangeClient(value: any) {
    this.filterData.clientId = value.id;
    this.filterData.clientName = value.display;
    this.cargoConfigList.pagination.page = 1;
    this.fetchListCargoConfig();
  }
}
