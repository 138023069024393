
import { Vue, Options, prop } from "vue-class-component";
import {
  SchedulePlaneCargo,
  SchedulePlaneCargoDetail
} from "@/domain/entities/CargoConfiguration";
import { OptionsClass } from "@/domain/entities/MainApp";
class Props {
  dataSchedule = prop<SchedulePlaneCargo[]>({
    default: []
  });
}
@Options({})
export default class TableSchedulePlane extends Vue.with(Props) {
  columns: any = [
    {
      name: "No.",
      styleHead: "w-16 text-left align-top text-black-lp-300 text-12px",
      styleCustom: "align-top",
      render: (item: SchedulePlaneCargo, index: any) => {
        return index + 1;
      }
    },
    {
      name: "Waktu Cut Off Cycle",
      styleHead: "w-28 text-left align-top text-black-lp-300 text-12px",
      styleCustom: "align-top",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail, index: any) => {
          html +=
            index === 0
              ? item.timeEnd
              : `<div class="flex"><img class="mr-1" src="${require("@/app/ui/assets/svg/plane-icon-black.svg")}" /> Transit ${index}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Hari Penerbangan",
      styleHead: "w-28 text-left align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          html += `<div>${(el.flightDay as OptionsClass).name}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Bandara Asal",
      styleHead: "w-500px text-center align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          html += `<div class='text-left whitespace-nowrap'>${this.flightDataOrigin(
            el
          )}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Bandara Tujuan",
      styleHead: "w-500px text-center align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          html += `<div class='text-left whitespace-nowrap'>${this.flightDataDestination(
            el
          )}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Estimasi Keberangkatan",
      styleHead:
        "w-36 text-left align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          html += `<div>${el.flightDepartureHourOnly}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Batas Waktu Pencarian",
      styleHead: "w-36 text-left align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          html += `<div>${el.flightTreshold} menit</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Jeda Penerbangan",
      styleHead: "w-36 text-left align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3'>";
        item.schedule.forEach((_: SchedulePlaneCargoDetail, index: number) => {
          html += `<div class="flex">${this.flightDayTransit(_, index)}</div>`;
        });
        html += "</div>";
        return html;
      }
    },
    {
      name: "Keterangan",
      styleHead: "w-36 text-left align-top text-black-lp-300 text-12px",
      render: (item: SchedulePlaneCargo) => {
        let html = "<div class='grid grid-cols-1 gap-y-3 whitespace-nowrap'>";
        item.schedule.forEach((_: SchedulePlaneCargoDetail, index: number) => {
          html += `<div class="flex">${this.dataDayTransit(_, index)}</div>`;
        });
        html += "</div>";
        return html;
      }
    }
  ];
  flightDataDestination(item: any) {
    if (item.flightAirportDestination) {
      return `${item.flightAirportDestination} - ${item.flightAirportDestinationName}`;
    }
    return "-";
  }
  flightDataOrigin(item: any) {
    if (item.flightAirportOrigin) {
      return `${item.flightAirportOrigin} - ${item.flightAirportOriginName}`;
    }
    return "-";
  }
  dataDayTransit(item: any, index: number) {
    if (index === 0) {
      return "-";
    }
    return item.flightDayTransit === 0
      ? "Hari Sama"
      : `Jeda ${item.flightDayTransit} Hari`;
  }
  flightDayTransit(item: any, index: number) {
    if (index === 0 && item.flightDayTransit === 0) {
      return "Hari Ini";
    } else if (index === 0 && item.flightDayTransit != 0) {
      return `${item.flightDayTransit} Hari`;
    } else {
      return item.flightDayTransit === 0
        ? "Transit Hari Ini"
        : `${item.flightDayTransit} Hari Transit`;
    }
  }
}
