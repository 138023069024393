
import { Vue, Options } from "vue-class-component";
import {
  CargoConfiguration,
  SchedulePlaneCargo,
  SchedulePlaneCargoDetail
} from "@/domain/entities/CargoConfiguration";
import {
  parsingErrorResponse,
  formatDate,
  checkRolePermission,
  formatTimeCustom
} from "@/app/infrastructures/misc/Utils";
import TableSchedulePlane from "@/app/ui/views/route/cargo-configuration/cargo-configuration-detail/table-schedule-plane.vue";
import { CARGO_CONFIGURATION } from "@/app/infrastructures/misc/RolePermission";
import { CargoConfigurationController } from "@/app/ui/controllers/CargoConfigurationController";
import { CargoController } from "@/app/ui/controllers/CargoController";
import DeleteConfigPopup from "@/app/ui/views/route/cargo-configuration/components/delete-config-popup.vue";

@Options({
  components: { TableSchedulePlane, DeleteConfigPopup }
})
export default class CargoConfigurationDetail extends Vue {
  mounted() {
    this.fetchDetailData();
  }

  // delete config
  deleteConfigModal = false;
  configSelected: any = "";

  get id(): string {
    return this.$route.params.id.toString();
  }
  goBack() {
    this.$router.push("/network/cargo-configuration");
  }
  goToEdit() {
    this.$router.push(`/network/cargo-configuration/${this.id}/edit`);
  }
  deleteConfig() {
    this.configSelected = {
      configName: `${this.detailCargoConfiguration.originCity.code} - ${this.detailCargoConfiguration.destinationCity.code}`,
      id: this.id,
      type: "detail"
    };
    this.deleteConfigModal = true;
  }
  onChipStatus(status: boolean) {
    if (status) {
      return "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
    }
    return "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
  }
  formatDate(date: string) {
    return formatDate(date);
  }
  get isTransportationType() {
    return typeof this.detailCargoConfiguration.transportationType === "string"
      ? this.detailCargoConfiguration.transportationType
      : this.detailCargoConfiguration.transportationType.value;
  }
  get isShowVendor() {
    return this.isTransportationType.match(/TRUCK|TRAIN|SHIP/gi);
  }
  get isShowPlaneForm() {
    return this.isTransportationType.match(/PLANE/gi);
  }

  get activeCutOffTime() {
    return this.detailCargoConfiguration?.cargoConfigurationCutOffTime.filter(
      cutOff => cutOff.ctStatus.toLowerCase() === "active"
    );
  }

  get clientBranch() {
    // if parent id and clientbranch empty array return Semua
    if (this.detailCargoConfiguration.clientParentId && this.detailCargoConfiguration.clientBranch.length === 0) {
      return 'Semua'
    } 
    else if (this.detailCargoConfiguration.clientParentId && this.detailCargoConfiguration.clientBranch.length > 0) {
      const branchName = this.detailCargoConfiguration.clientBranch.map((item) => item.clientBranchName).join(", ")
      return branchName
    }

    return '-'
  }

  // data detail
  isLoading = false;
  errorCause = "";
  detailCargoConfiguration = new CargoConfiguration();
  async fetchDetailData() {
    this.errorCause = "";
    this.isLoading = true;
    try {
      this.detailCargoConfiguration = await CargoConfigurationController.getDetailCargoConfiguration(
        Number(this.id)
      );
      this.getAirportOriginName();
      this.getAirportDestinationName();
    } catch (err) {
      this.errorCause = parsingErrorResponse(err).type;
    } finally {
      this.isLoading = false;
    }
  }

  get isEditAble() {
    return checkRolePermission(CARGO_CONFIGURATION.EDIT);
  }

  formatTime(date: string): string {
    return formatTimeCustom({ date, isSecond: false });
  }

  getAirportOriginName() {
    const tmpSchedule: any = [];
    const tmpAirportName: any = [];
    this.detailCargoConfiguration.schedulePlane.forEach(
      (item: SchedulePlaneCargo) => {
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          const findIndex = tmpSchedule.findIndex((data: any) => {
            return data === el.flightAirportOrigin;
          });
          if (findIndex === -1) {
            tmpSchedule.push(el.flightAirportOrigin);
          }
        });
      }
    );
    tmpSchedule.forEach(async (data: any) => {
      if (tmpSchedule.length) {
        CargoController.getAirportDetailOrigin({
          airportCode: data
        }).then(() => {
          const airportOriginName = {
            airportCode: CargoController.airportDetailOrigin.airportCode,
            airportName: CargoController.airportDetailOrigin.airportName
          };
          tmpAirportName.push(airportOriginName);
          if (tmpAirportName.length) {
            this.detailCargoConfiguration.schedulePlane.forEach(
              (item: SchedulePlaneCargo, index: number) => {
                item.schedule.forEach(
                  async (el: SchedulePlaneCargoDetail, idx: number) => {
                    const airportData = tmpAirportName.find(
                      (dataAirportName: any) => {
                        return (
                          dataAirportName.airportCode === el.flightAirportOrigin
                        );
                      }
                    );
                    if (airportData) {
                      this.detailCargoConfiguration.schedulePlane[
                        index
                      ].schedule[idx].flightAirportOriginName =
                        airportData.airportName;
                    }
                  }
                );
              }
            );
          }
        });
      }
    });
  }

  getAirportDestinationName() {
    const tmpSchedule: any = [];
    const tmpAirportName: any = [];
    this.detailCargoConfiguration.schedulePlane.forEach(
      (item: SchedulePlaneCargo) => {
        item.schedule.forEach((el: SchedulePlaneCargoDetail) => {
          const findIndex = tmpSchedule.findIndex((data: any) => {
            return data === el.flightAirportDestination;
          });
          if (findIndex === -1) {
            tmpSchedule.push(el.flightAirportDestination);
          }
        });
      }
    );
    tmpSchedule.forEach(async (data: any) => {
      if (tmpSchedule.length) {
        await CargoController.getAirportDetailDestination({
          airportCode: data
        }).then(() => {
          const airportDestinationName = {
            airportCode: CargoController.airportDetailDestination.airportCode,
            airportName: CargoController.airportDetailDestination.airportName
          };
          tmpAirportName.push(airportDestinationName);
          if (tmpAirportName.length) {
            this.detailCargoConfiguration.schedulePlane.forEach(
              (item: SchedulePlaneCargo, index: number) => {
                item.schedule.forEach(
                  async (el: SchedulePlaneCargoDetail, idx: number) => {
                    const airportData = tmpAirportName.find(
                      (dataAirportName: any) => {
                        return (
                          dataAirportName.airportCode ===
                          el.flightAirportDestination
                        );
                      }
                    );
                    if (airportData) {
                      this.detailCargoConfiguration.schedulePlane[
                        index
                      ].schedule[idx].flightAirportDestinationName =
                        airportData.airportName;
                    }
                  }
                );
              }
            );
          }
        });
      }
    });
  }
}
