
import { Vue, prop, Options } from "vue-class-component";
import { SchedulePlane } from "@/domain/entities/CargoConfiguration";
import { OptionsClass } from "@/domain/entities/MainApp";
import {
  dayOfWeekList,
  optionFlightBreak,
  optionFlightBreakTransit
} from "@/app/infrastructures/misc/Constants";
import formatTimeHoursMinutes from "@/app/infrastructures/misc/common-library/FormatTimeHoursMinutes";
import { CargoController } from "@/app/ui/controllers/CargoController";
import { debounce } from "lodash";
import numberOnly from "@/app/infrastructures/misc/common-library/NumberOnly";

class Props {
  schedules = prop<Array<SchedulePlane>>({
    type: Array,
    default: []
  });
  isDefaultValue = prop<boolean>({
    default: false
  });
  originCity = prop<any>({
    default: ""
  });
  destinationCity = prop<any>({
    default: ""
  });
}

@Options({
  emits: ["update:schedules"]
})
export default class PlaneTransit extends Vue.with(Props) {
  mounted() {
    this.onProcessCutOffTime();
    if (this.isDefaultValue) {
      this.getAirportOriginName();
      this.getAirportDestinationName();
    }
  }
  created() {
    if (this.isDefaultValue && !this.schedules.length) {
      this.schedules.push(
        new SchedulePlane({
          idParent: 1,
          endTime: "",
          flightNo: "",
          departureDate: "",
          departureTime: "",
          arrivalDate: "",
          arrivalTime: "",
          isTransit: false,
          orderTransit: 1,
          transit: [],
          key: 1
        })
      );
    }
  }
  table = {
    header: [
      { label: "No.", width: "50px" },
      { label: "Waktu Cut Off Cycle", asterisk: true, width: "170px" },
      { label: "Hari Penerbangan", asterisk: true, width: "150px" },
      { label: "Estimasi Keberangkatan", asterisk: true, width: "150px" },
      { label: "Batas Waktu Pencarian", asterisk: true, width: "150px" },
      { label: "Jeda Penerbangan", asterisk: true, width: "150px" },
      { label: "Atur", width: "150px" },
      { label: "", width: "50px" }
    ]
  };

  // select
  isOpenSelect = false;
  get options(): OptionsClass[] {
    return dayOfWeekList;
  }

  dataShedule: SchedulePlane[] = [];

  onProcessCutOffTime(): void {
    const data: SchedulePlane[] = [];
    this.schedules.forEach((item: SchedulePlane) => {
      if (item.status.match(/^active$/gi)) {
        data.push(item);

        if (item.transit.length > 0) {
          for (const el of item.transit) {
            data.push(el);
          }
        }
      }
    });
    this.dataShedule = data;
  }

  checkTransit(transit: Array<any>, idParent: number, index: number) {
    const newParentId = this.dataShedule[index + 1]?.idParent;

    if (transit.length > 0 || idParent === newParentId) {
      return true;
    }

    return false;
  }

  maxTransit(idParent: number) {
    const maxTransit = this.dataShedule.filter(
      (item: any) => item.idParent === idParent
    );

    return maxTransit.length > 3;
  }

  addSchedule() {
    const lastIdParent = this.schedules[this.schedules.length - 1];

    const initialDataOriginAirport = CargoController.airportDataOrigin.data;
    const initialDataDestinationAirport =
      CargoController.airportDataDestination.data;
    if (this.isDefaultValue) {
      CargoController.getAirportListOrigin({
        airportCode: "",
        cityCode: this.originCity.code,
        page: 1,
        limit: 10,
        isLocation: true
      });
      CargoController.getAirportListDestination({
        airportCode: "",
        cityCode: this.destinationCity.code,
        page: 1,
        limit: 10,
        isLocation: true
      });
    }

    const airportOriginData = initialDataOriginAirport.length
      ? CargoController.airportDataOrigin.data
          .map(item => ({
            ...item,
            airportName: `${item.airportCode} - ${item.airportName}`
          }))
          .reduce((acc, item) => ({
            ...acc,
            item
          }))
      : "";
    const airportDestinationData = initialDataDestinationAirport.length
      ? CargoController.airportDataDestination.data
          .map(item => ({
            ...item,
            airportName: `${item.airportCode} - ${item.airportName}`
          }))
          .reduce((acc, item) => ({
            ...acc,
            item
          }))
      : "";

    this.schedules.push(
      new SchedulePlane({
        idParent: lastIdParent.idParent + 1,
        endTime: "",
        flightNo: "",
        departureDate: "",
        departureTime: "",
        arrivalDate: "",
        arrivalTime: "",
        isTransit: false,
        orderTransit: 1,
        airportOrigin: airportOriginData,
        airportDestination: airportDestinationData,
        flightTreshold: 1440,
        transit: [],
        key: lastIdParent.key + 1
      })
    );
    this.onProcessCutOffTime();
  }

  addTransit(idParent: number) {
    const indexOf = this.schedules.findIndex(
      (item: any) => item.idParent === idParent
    );

    const checkTransit = this.schedules[indexOf].transit.length;

    const initialDataOriginAirport = CargoController.airportDataOrigin.data;
    const initialDataDestinationAirport =
      CargoController.airportDataDestination.data;
    if (this.isDefaultValue) {
      CargoController.getAirportListOrigin({
        airportCode: "",
        cityCode: this.originCity,
        page: 1,
        limit: 10,
        isLocation: true
      });
      CargoController.getAirportListDestination({
        airportCode: "",
        cityCode: this.destinationCity,
        page: 1,
        limit: 10,
        isLocation: true
      });
    }

    const airportOriginData = initialDataOriginAirport.length
      ? CargoController.airportDataOrigin.data
          .map(item => ({
            ...item,
            airportName: `${item.airportCode} - ${item.airportName}`
          }))
          .reduce((acc, item) => ({
            ...acc,
            item
          }))
      : "";
    const airportDestinationData = initialDataDestinationAirport.length
      ? CargoController.airportDataDestination.data
          .map(item => ({
            ...item,
            airportName: `${item.airportCode} - ${item.airportName}`
          }))
          .reduce((acc, item) => ({
            ...acc,
            item
          }))
      : "";

    this.schedules[indexOf].transit.push(
      new SchedulePlane({
        idParent: idParent,
        endTime: "",
        flightNo: "",
        departureDate: "",
        departureTime: "",
        arrivalDate: "",
        arrivalTime: "",
        isTransit: true,
        orderTransit: checkTransit < 1 ? 2 : checkTransit === 1 ? 3 : 4,
        airportOrigin: airportOriginData,
        airportDestination: airportDestinationData,
        flightTreshold: 1440,
        transit: [],
        key: 1000 * (idParent + 1) + checkTransit
      })
    );
    this.onProcessCutOffTime();
  }

  showDeleteConfirmation = false;
  idParent = 0;
  orderTransit = 0;
  get activeSchedules() {
    const tmp: any = [];
    const activeData = this.schedules.filter(
      (val: any) => val.status === "ACTIVE"
    );
    activeData.forEach((val: any, index: number) => {
      if (val.status === "ACTIVE") {
        tmp.push({ ...val, no: index + 1 });
      }
    });
    return tmp;
  }
  getNumber(data: any) {
    const detail = this.activeSchedules.find(
      (item: any) => item.key === data.key
    );
    return detail?.no;
  }
  get activeDataSchedule() {
    return this.dataShedule.filter((val: any) => val.status === "ACTIVE");
  }
  confirmDeleteSchedule(idParent: number, orderTransit: number) {
    this.showDeleteConfirmation = true;
    this.idParent = idParent;
    this.orderTransit = orderTransit;
  }

  closeConfirmation() {
    this.showDeleteConfirmation = false;
    this.idParent = 0;
    this.orderTransit = 0;
  }

  deleteSchedule(): void {
    const indexOfParent = this.schedules.findIndex(
      (item: any) => item.idParent === this.idParent
    );

    if (this.orderTransit === 1) {
      this.schedules[indexOfParent].status = "INACTIVE";
      this.closeConfirmation();
    } else {
      const indexOfTransit = this.schedules[indexOfParent].transit.findIndex(
        (item: any) => item.orderTransit === this.orderTransit
      );

      this.schedules[indexOfParent].transit.splice(indexOfTransit, 1);

      if (
        indexOfTransit === 0 &&
        this.schedules[indexOfParent].transit.length > 0
      ) {
        this.schedules[indexOfParent].transit[0].orderTransit = 2;
        this.schedules[indexOfParent].transit[1].orderTransit = 3;
      }
      this.closeConfirmation();
    }
    this.onProcessCutOffTime();
  }
  get filterCutOffTimeByStatus(): SchedulePlane[] {
    if (this.isDefaultValue) {
      return this.schedules.filter(
        (item: SchedulePlane) => item.status !== "INACTIVE"
      );
    }
    return this.schedules;
  }
  formatCutOffTimeValue(params: { value: string; index: number }): void {
    this.onValidateCutOffTime();
  }
  formatCutOffDayValue(params: { value: any; index: number }): void {
    this.onValidateCutOffTime();
  }
  onValidateCutOffTime(): void {
    if (this.schedules.length > 1) {
      this.schedules.forEach((item: any, index: number) => {
        if (index > 0) {
          const before = this.schedules[index - 1];
          const current = item.endTime;
          const currentDay = item.flightDay.id;
          const dayBefore = (before.flightDay as OptionsClass).id || 0;
          const formIndex = this.dataShedule.findIndex(e => e.key === item.key);

          if (currentDay < dayBefore) {
            this.dataShedule[formIndex].isError = true;
            this.dataShedule[formIndex].description = `Jam cycle ${index +
              1} harus lebih dari cycle ${index}`;
          } else if (currentDay === dayBefore) {
            this.dataShedule[formIndex].isError =
              formatTimeHoursMinutes(current).getTime() <=
              formatTimeHoursMinutes(before.endTime).getTime();
            this.dataShedule[formIndex].description = `Jam cycle ${index +
              1} harus lebih dari cycle ${index}`;
          } else {
            this.dataShedule[formIndex].isError = false;
            this.dataShedule[formIndex].description = "";
          }
        }
      });
    }
  }

  optionDayTransit(item: any) {
    return item.isTransit ? optionFlightBreakTransit : optionFlightBreak;
  }

  numberOnly(value: string) {
    return numberOnly(value);
  }

  fetchAirport = debounce((search: string) => {
    search = search || "";
    search = search.split(" - ").length > 1 ? search.split(" - ")[1] : search;
    if (!search.length || search.length >= 3) {
      CargoController.getAirportList({
        airportCode: search,
        cityCode: "",
        page: 1,
        limit: 10,
        isLocation: false,
        search: ""
      });
    }
  }, 250);
  get isLoadingAirport() {
    return CargoController.isLoadingAirport;
  }
  get airportData() {
    return CargoController.airportData.data.map(item => ({
      ...item,
      airportName: `${item.airportCode} - ${item.airportName}`
    }));
  }
  getAirportOriginName() {
    const tmpSchedule: any = [];
    const tmpAirportName: any = [];
    this.activeDataSchedule.forEach((item: SchedulePlane) => {
      const findIndex = tmpSchedule.findIndex((dataOrigin: any) => {
        return dataOrigin === item.airportOrigin;
      });
      if (findIndex === -1) {
        tmpSchedule.push(item.airportOrigin);
      }
      item.transit.forEach((el: SchedulePlane) => {
        const findIndexTransit = tmpSchedule.findIndex((data: any) => {
          return data === el.airportOrigin;
        });
        if (findIndexTransit === -1) {
          tmpSchedule.push(el.airportOrigin);
        }
      });
    });
    tmpSchedule.forEach(async (data: any) => {
      if (tmpSchedule.length) {
        CargoController.getAirportDetailOrigin({
          airportCode: data
        }).then(() => {
          const airportOriginName = {
            airportCode: CargoController.airportDetailOrigin.airportCode,
            airportName: CargoController.airportDetailOrigin.airportName
          };
          tmpAirportName.push(airportOriginName);
          if (tmpAirportName.length) {
            this.activeDataSchedule.forEach(
              (item: SchedulePlane, index: number) => {
                const airportData = tmpAirportName.find(
                  (dataAirportName: any) => {
                    return dataAirportName.airportCode === item.airportOrigin;
                  }
                );

                if (airportData) {
                  this.activeDataSchedule[index].airportOrigin = {
                    airportCode: airportData.airportCode,
                    airportName: `${airportData.airportCode} - ${airportData.airportName}`
                  };
                }
                item.transit.forEach((el: SchedulePlane, idx: number) => {
                  const airportDataTransit = tmpAirportName.find(
                    (dataTransit: any) => {
                      return dataTransit.airportCode === el.airportOrigin;
                    }
                  );

                  if (airportDataTransit) {
                    this.activeDataSchedule[index].transit[
                      idx
                    ].airportOrigin = {
                      airportCode: airportDataTransit.airportCode,
                      airportName: `${airportDataTransit.airportCode} - ${airportDataTransit.airportName}`
                    };
                  }
                });
              }
            );
          }
        });
      }
    });
  }
  getAirportDestinationName() {
    const tmpSchedule: any = [];
    const tmpAirportName: any = [];
    this.activeDataSchedule.forEach((item: SchedulePlane) => {
      const findIndex = tmpSchedule.findIndex((dataDestination: any) => {
        return dataDestination === item.airportDestination;
      });
      if (findIndex === -1) {
        tmpSchedule.push(item.airportDestination);
      }
      item.transit.forEach((el: SchedulePlane) => {
        const findIndexTransit = tmpSchedule.findIndex((data: any) => {
          return data === el.airportDestination;
        });
        if (findIndexTransit === -1) {
          tmpSchedule.push(el.airportDestination);
        }
      });
    });
    tmpSchedule.forEach(async (data: any) => {
      if (tmpSchedule.length) {
        await CargoController.getAirportDetailDestination({
          airportCode: data
        }).then(() => {
          const airportDestinationName = {
            airportCode: CargoController.airportDetailDestination.airportCode,
            airportName: CargoController.airportDetailDestination.airportName
          };
          tmpAirportName.push(airportDestinationName);
          if (tmpAirportName.length) {
            this.activeDataSchedule.forEach(
              (item: SchedulePlane, index: number) => {
                const airportDataDestination = tmpAirportName.find(
                  (dataAirportNameDestination: any) => {
                    return (
                      dataAirportNameDestination.airportCode ===
                      item.airportDestination
                    );
                  }
                );

                if (airportDataDestination) {
                  this.activeDataSchedule[index].airportDestination = {
                    airportCode: airportDataDestination.airportCode,
                    airportName: `${airportDataDestination.airportCode} - ${airportDataDestination.airportName}`
                  };
                }
                item.transit.forEach((el: SchedulePlane, idx: number) => {
                  const airportDataTransitDestination = tmpAirportName.find(
                    (dataTransitDestination: any) => {
                      return (
                        dataTransitDestination.airportCode ===
                        el.airportDestination
                      );
                    }
                  );

                  if (airportDataTransitDestination) {
                    this.activeDataSchedule[index].transit[
                      idx
                    ].airportDestination = {
                      airportCode: airportDataTransitDestination.airportCode,
                      airportName: `${airportDataTransitDestination.airportCode} - ${airportDataTransitDestination.airportName}`
                    };
                  }
                });
              }
            );
          }
        });
      }
    });
  }
}
